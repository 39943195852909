<template>
  <ul class="menu-nav">
    
    <router-link v-if="isDashboard" to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li> 
    </router-link>

    <li v-if="isTransaction" aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/ekspedisi/transaksi') }">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-database"></i>
        <span class="menu-text">Transaksi</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Transaksi</span>
            </span>
          </li>
          <router-link v-if="isTransaction" to="/ekspedisi/transactions/surat_jalan-list" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Surat Jalan</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li v-if="isLaporan" aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/ekspedisi/laporan') }">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-database"></i>
        <span class="menu-text">Laporan</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Laporan</span>
            </span>
          </li>
          <router-link v-if="isLaporan" to="/ekspedisi/laporan/surat_jalan-list" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Laporan surat jalan</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    
    
    <li v-if="isMaster" aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/ekspedisi/master') }">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-database"></i>
        <span class="menu-text">Master</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Master</span>
            </span>
          </li>
          
          
          <router-link to="/ekspedisi/master/news" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">News</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/customer" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Customer</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/driver" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Driver</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/kendaraan" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Kendaraan</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/jenis_pengiriman" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Jenis Pengiriman</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/jenis_pengiriman_detail" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Jenis Pengiriman Detail</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/satuan" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Satuan</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/tipe_pembayaran" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Tipe Pembayaran</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/status_tracking" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Status Tracking</span>
              </a>
            </li>
          </router-link>
          <router-link to="/ekspedisi/master/ongkos_kirim" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Ongkos kirim</span>
              </a>
            </li>
          </router-link>
          
          
        </ul>
      </div>
    </li>
    <li v-if="isSetup"   aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/ekspedisi/setup') }">
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-cogs"></i>
        <span class="menu-text">Pengaturan</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Pengaturan</span>
            </span>
          </li>
          

          <router-link  to="/ekspedisi/modul" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Module</span>
              </a>
            </li>
          </router-link>
          <router-link  to="/ekspedisi/permision" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Permision</span>
              </a>
            </li>
          </router-link>

          <router-link  to="/ekspedisi/modul_permision" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Module Permision</span>
              </a>
            </li>
          </router-link>
          <router-link  to="/ekspedisi/user-roles" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"> <span></span> </i>
                <span class="menu-text">Roles</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>

    
    
    <router-link v-if="isUser" to="/ekspedisi/users" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Pengguna</span>
        </a>
      </li>
    </router-link>

  

  </ul>
</template>

<script>
import { mapGetters } from "vuex";  

export default {
  computed: {
    ...mapGetters([
      "currentModule"
    ]),
  },
  name: "KTMenu",
  data() {
    return {
      isMaster: false,
      isDashboard: false,
      isUser: false,
      isTransaction: false,
      isSetup: false,
      isLaporan :false
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  mounted() {
    let menu = this.currentModule ? this.currentModule : [];

    menu.forEach(e => { 
      if(e.module_kode == "M01") this.isUser = true;
      else if(e.module_kode == "M02") this.isMaster = true;
      else if(e.module_kode == "M03") this.isDashboard = true;
      else if(e.module_kode == "M04") this.isTransaction = true;
      else if(e.module_kode == "M05") this.isSetup = true;
      else if(e.module_kode == "M06") this.isLaporan = true;
    });
  }
};
</script>
