<template>
  <div class="topbar-item">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle" >
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
        Hallo,
      </span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
        {{ currentUser.name ? currentUser.name : undefined }}
      </span>
      <span class="symbol symbol-35 symbol-light-warning">
        <img v-if="false" alt="Pic" :src="picture" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUser.name ? currentUser.name.charAt(0).toUpperCase() : undefined }}
        </span>
      </span>
    </div>

    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5" >
        <h3 class="font-weight-bold m-0">
          User Profile
          <!-- <small class="text-muted font-size-sm ml-2">12 messages</small> -->
        </h3>
        <a
          href="javascript:void(0)"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a href="javascript:void(0)" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {{ currentUser.name || '' }}
            </a>
            <div class="navi mt-2">
              <a href="javascript:void(0)" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <inline-svg src="media/svg/icons/Communication/Mail-notification.svg"/>
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.email || '' }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <a href="javascript:void(0)" class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/Communication/Address-card.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="text-muted">Role</div>
                <div class="font-weight-bold">
                  {{currentUser.role_nama ? currentUser.role_nama : "-"}}
                </div>
              </div>
              
            </div>
          </a>
        </div>
        
        
        
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #kt_quick_user {
    overflow: hidden;
  }
</style>

<script>
import { mapGetters } from "vuex";  
import { LOGOUT,SET_AUTH } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";


export default {
  name: "KTQuickUser",
  data() {
    return {
      default_masjid_id : 0,
      tContentsLoading : false,
      user: {email: null, name: null, initial: null}
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.default_masjid_id = this.currentUser.masjid_id;
  },
  
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }))
        .catch(() => this.$router.push({ name: "login" }));
    },

    optionMasjid(masjid_id){
        if(masjid_id != undefined){
          // console.log(JwtService.getToken());
          let query = {
            masjid_id: masjid_id,
            user_id : this.currentUser.id,
            token : JwtService.getToken()
          }

        //   this.$store.dispatch(UPDATE_MASJID, query).then(() => {
        //   this.$router.push({ name: "dashboard" })
        //   this.loadingLogin = false;
        // }).catch(() => {
        //     this.loadingLogin = false;
        // });


          this.tContentsLoading = true;
          ApiService.setHeaderMultipart();
          ApiService.post("/user/updateMasjid", query, 1).then(({ data }) => {
            // console.log(data);
            this.$store.commit(SET_AUTH, data);
            this.tContentsLoading = false;
            this.$swal("Berhasil","Filter masjid berhasil di pilih ", "success");
            this.$router.push({ path: "/login" });
          }).catch(({ response }) => {
            this.tContentsLoading = false;
            return this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
          })
        }
          
    }
    
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    picture() {
      return process.env.BASE_URL + "media/users/default.jpg";
    }
  }
};
</script>
